import * as Sentry from '@sentry/browser'
import authClient from './oktaAuth'

export class SantryIOModule {
    initialize() {
        Sentry.onLoad(() => { 
            Sentry.init({
                dsn: "https://76253fb273c28f663a4e949aa4a40250@o4505551656517632.ingest.sentry.io/4506232240472064",
                environment: TARGET_ENV,
                debug: false
            })

            authClient.token.getUserInfo().then(user => {
                Sentry.setUser({ id: user.sub })
            })
        })
    }
    
    captureException(error) {
        Sentry.captureException(error)
    }
}

const SantryIO = new SantryIOModule()
export default SantryIO
