import Axios from 'axios'
import { config } from '../../../Config'

let cache = {}

class ApiBase {
  getWithCache = url => new Promise((resolve, reject) => {
    if (cache[url] !== undefined) {
      resolve(cache[url])
    } else {
      Axios.get(`${config.AirportsAPI}${url}`)
        .then(response => { this._setResp(response, resolve, reject) })
        .catch(err => { reject(err) })
    }
  })

  get = url => new Promise((resolve, reject) => {
    Axios.get(`${config.AirportsAPI}${url}`)
      .then(response => { this._setResp(response, resolve, reject) })
      .catch(err => { reject(err) })
  })

  post = (url, data) => new Promise((resolve, reject) => {
    Axios.post(`${config.AirportsAPI}${url}`, data)
      .then(response => this._setResp(response, resolve, reject))
      .catch(err => reject(err))
  })

  put = url => new Promise((resolve, reject) => {
    Axios.put(`${config.AirportsAPI}${url}`)
      .then(response => { this._setResp(response, resolve, reject) })
      .catch(err => { reject(err) })
  })

  delete = (url, data) => new Promise((resolve, reject) => {
    Axios.delete(`${config.AirportsAPI}${url}`, { data: data })
      .then(response => this._setResp(response, resolve, reject))
      .catch(err => reject(err))
  })

  _setResp = (response, resolve, reject) => {
    if (response.status !== 200) {
      reject(response.statusText)
    } else {
      resolve(response.data)
    }
  }

  postFile = (url, data) => new Promise((resolve, reject) => {
    Axios.post(`${url}`, data, { responseType: 'arraybuffer' })
      .then(response => this._setResp(response, resolve, reject))
      .catch(err => reject(err))
  })
}

const apiBase = new ApiBase()

export default apiBase