import { OktaAuth } from '@okta/okta-auth-js'
import { config } from '../Config'

const authClient = new OktaAuth({
    issuer: config.OktaIssuer,
    clientId: config.OktaClientID,
    redirectUri: window.location.origin,
    // https://github.com/okta/okta-auth-js#transformauthstate
    transformAuthState: async (oktaAuth, authState) => {
        if (!authState.isAuthenticated) {
          return authState
        }

        // Extra requirement: user must have valid Okta SSO session
        const user = await oktaAuth.token.getUserInfo()
        authState.isAuthenticated = !!user // convert to boolean
        authState.users = user // also store user object on authState
        return authState
      }
})

export default authClient