// Default values at the top overriden for specific envirnoments
let websiteHost = 'https://localhost:44318'
let mixpanelToken = '4fb38938ca8bc0cd279b817667dcf3fd'
let debugMode = false
let OktaBaseURL = 'https://medaire-sandbox.oktapreview.com'
let OktaIssuer = 'https://medaire-sandbox.oktapreview.com/oauth2/default'
let OktaClientID = '0oa53qhwgp8HNGH1P0x7'
let medaireSecurityCMSPortal = 'https://medairecms.medaire.com'
if (TARGET_ENV === 'staging') {
    websiteHost = 'https://medairecp.journeydigital.nz'
    medaireSecurityCMSPortal = 'https://medaire-security-cms-test-umbraco.azurewebsites.net'
}
if (TARGET_ENV === 'uat') {
    websiteHost = 'https://staging-app.medaire.com'
    medaireSecurityCMSPortal = 'https://staging-medairecms.medaire.com'
}
if (TARGET_ENV === 'production') {
    websiteHost = 'https://app.medaire.com'
    mixpanelToken = '532aa85b37a2d5b3eedbc1a2d69d343d'
    debugMode = false
}
if (TARGET_ENV === 'production' || TARGET_ENV === 'uat') {
    OktaIssuer = 'https://medaire.okta.com/oauth2/default'
    OktaClientID = '0oa1bg83nqM9V9slV417'
    OktaBaseURL = 'https://medaire.okta.com'
}
export const config = {
    MedAireSecurityAPI: websiteHost + '/MedAireSecurityApi/api/v1',
    ReportAPI: websiteHost + '/api/report/Download',
    CovidAPI: websiteHost,
    HotelSecurityAdvisoryApi: websiteHost + '/api/HotelSecurityAdvisoryApi',
    SecurityCMS: websiteHost + '/SecurityCmsApi',
    AirportsAPI: websiteHost + '/api',
    LuxuryYachtingAPI: websiteHost + '/api/ly',
    LuxuryYachtingInventoryAPI: websiteHost + '/api/Inventory',
    RiskIntelligence: websiteHost + '/api/RiskIntelligence',
    ShopifyAPI: websiteHost + '/api/Shopify',
    AvsecAlertsAPI: websiteHost + '/api/avsecalert',
    QuantexaAPI: websiteHost + '/api/News',
    UserAPI: websiteHost + '/api/User',
    MedaireSecurityCMSPortal: medaireSecurityCMSPortal,
    OktaIssuer: OktaIssuer,
    OktaClientID: OktaClientID,
    OktaBaseURL: OktaBaseURL,
    debugMode: debugMode,
    mixpanelToken: mixpanelToken,
    // Created with Mapbox username: simonmarshall
    MapBoxAccessToken: 'pk.eyJ1Ijoic2ltb25tYXJzaGFsbCIsImEiOiJja3ZoNG9iNHE0aGZ4MnVxcHNnbnk1cXIyIn0.9u_koXXocNFAO_2fcHoUuA',
    SecurityModuleMapStyleURL: 'mapbox://styles/simonmarshall/cl51tv8zg000014kbouu7gnq3',
    LuxuryYachtingModuleMapStyleURL: 'mapbox://styles/simonmarshall/cl5snd2yj000114p9rshoebf5',
    LuxuryYachtingModulePortDetailsMapStyleURL: 'mapbox://styles/mapbox/outdoors-v12',
    websiteHost: websiteHost,
    airQualityURL: 'https://site.internationalsos.com/airpollution/?membernumber=11BWEB000041',
    covid19URL: 'https://pandemic.internationalsos.com/2019-ncov/?membernumber=11BWEB000041',
    pandemicPreparednessURL: 'https://pandemic.internationalsos.com/?membernumber=11BWEB000041&sc_lang=en'
}